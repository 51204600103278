import { Grid, Icon, Link, Text } from '@lemonenergy/lemonpie'
import { useLoaderData } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useState } from 'react'
import styled, { css } from 'styled-components'

import Section from '~/components/Section'
import Title from '~/components/Title'
import type { questions as faqQuestions } from '~/constants/faqQuestions'
import { gtag } from '~/utils/gtagEvents'

const SectionWrapper = styled(Section)(
  ({ theme: { spacing, media } }) => css`
    ${Title} {
      margin-bottom: ${spacing(6)};

      ${media.sm} {
        margin-bottom: ${spacing(12)};
      }
    }

    ${Grid.Container} {
      width: 100%;
    }
  `,
)

const Questions = styled.div(
  () => css`
    width: 100%;
  `,
)

const QuestionContainer = styled.div(
  ({ theme: { borderStyle, borderWidth, colors, spacing } }) => css`
    border-bottom: ${borderWidth.main} ${borderStyle.solid};
    border-color: ${colors.neutral.main};
    cursor: pointer;
    padding: ${spacing.lg} 0;
    width: 100%;
  `,
)

const QuestionBox = styled.div(
  () => css`
    display: flex;
    justify-content: space-between;
  `,
)

const AnswerBox = styled.div(
  ({ theme: { spacing } }) => css`
    padding: ${spacing.xl} 0 ${spacing.xs};
    white-space: pre-line;
  `,
)

type QuestionProps = {
  index: number
  question: string
  answer: string
  link?: { text: string; href: string }
}

const Question = ({ question, answer, link, index }: QuestionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const trackQuestionClick = (e: BaseSyntheticEvent) => {
    setIsOpen(!isOpen)

    gtag(
      {
        event: 'click_interaction',
        source_id: `question-${index}`,
        interaction_type: 'dropdown',
        label: question,
        interaction_result: isOpen ? 'close' : 'open',
      },
      e,
    )
  }

  return (
    <QuestionContainer onClick={trackQuestionClick}>
      <QuestionBox>
        <Text bold variant={{ xs: 'subtitle100', sm: 'title200' }}>
          {question}
        </Text>
        <Icon name={isOpen ? 'minus' : 'plus'} size="sm" />
      </QuestionBox>
      {isOpen && (
        <AnswerBox>
          <Text variant={{ xs: 'body100', sm: 'title300' }}>
            {answer}
            {link ? <Link href={link.href}>{link.text}</Link> : null}
          </Text>
        </AnswerBox>
      )}
    </QuestionContainer>
  )
}

type FAQProps = {
  title?: string
  id?: string
}

const Faq = ({
  title = 'outras perguntas que você pode ter',
  id = 'faqSection',
}: FAQProps) => {
  const { questions } = useLoaderData<{ questions: typeof faqQuestions }>()

  return (
    <SectionWrapper id={id}>
      <Grid.Container>
        <Title>{title}:</Title>
        <Questions>
          {questions.map((question, index) => (
            <Question
              key={question.answer}
              answer={question.answer}
              question={question.question}
              link={question?.link}
              index={index}
            />
          ))}
        </Questions>
      </Grid.Container>
    </SectionWrapper>
  )
}

export default Faq
